@tailwind base;
@tailwind components;

// Code syntax highlighting,
// powered by https://highlightjs.org
//@import '~highlight.js/styles/a11y-light.css';

@import 'base';
@import 'navigation';
@import 'mailchimp';
@import 'blog';

@tailwind utilities;

body{
  font-family: sans-serif;
}

p{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.home-list {
  a{
    color: #4a4a4a;
  }
}