body {
    font-size: 17px;
    background-color: #ffffff;
    color: #282828;
    line-height: 2;
    padding-left: 20px;
    padding-right: 20px;
    word-wrap: break-word;
}

a {
    @apply .font-semibold;
    @apply .no-underline;
    @apply .text-orange-500;

    &:hover {
        @apply .text-orange-800;
    }
}

hr{
    border-bottom-width: 0 !important;
}

blockquote {
    @apply .border-orange-400;
    @apply .border-l-4;
    @apply .font-normal;
    @apply .italic;
    @apply .my-8;
    @apply .pl-6;
    @apply .text-lg;
}

code {
    background-color: #eeeeee;
    @apply .text-orange-600;
    @apply .px-2;
    @apply .py-1;
    @apply .rounded;
    @apply .text-sm;
}

code.hljs {
    @apply .p-0;

    .hljs-comment,
    .hljs-keyword,
    .hljs-meta {
        @apply .font-normal;
        @apply .not-italic;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply border-b border-orange-400 pb-2;
    line-height: 1.6;
    @apply .mb-4;
    @apply .mt-8;

    &:first-child {
        @apply .mt-0;
    }
}

h1 {
    @apply .font-extrabold;
    @apply .text-5xl;
}

h2 {
    @apply .font-bold;
    @apply .text-4xl;
}

h3 {
    @apply .font-bold;
    @apply .text-3xl;
}

h4 {
    font-weight: 700 !important;
    @apply .font-normal;
    @apply .text-2xl;
}

h5 {
    font-weight: 700 !important;;
    @apply .font-normal;
    @apply .text-xl;
}

h6 {
    font-weight: 700 !important;;
    @apply .font-light;
    @apply .text-lg;
}

hr {
    @apply .border-b;
    @apply .border-orange-200;
    @apply .my-12;
    @apply .rounded-full;
}

li {
    ul,
    ol {
        @apply .my-0;
        //@apply .ml-8;
    }
}

ol,
ul {
    @apply .my-4;
    @apply .ml-0;
}

ol {
    @apply .list-decimal;
}

ul {
    @apply .list-disc;
}

p {
    @apply .my-3;

    @screen md {
        @apply .my-6;
    }
}

pre {
    @apply .leading-loose;
    @apply .my-6;
    @apply .overflow-x-auto;
    @apply .rounded;
    @apply .shadow;
    @apply .text-base;

    code {
        @apply .bg-transparent;
        @apply .block;
        @apply .py-2;
    }
}

::selection {
    @apply .bg-orange-400;
    @apply .text-white;
}

.post-content{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply border-b border-orange-400 pb-2;
    }
}

.follow-banner{
    position: fixed;
    background: #b5b4b4;
    bottom: 0;
    left: 0;
    right : 0;
    text-align: center;
}

table{
    @apply table-auto;
    border: 1px solid #808080;
    padding: 10px;
}

@media (max-width: 600px) {
    table{
        display: block;
        max-width: -moz-fit-content;
        max-width: fit-content;
        margin: 0 auto;
        overflow-x: auto;
        white-space: nowrap;
    }
}

th{
    text-align: left !important;
    padding: 5px;
    background-color: #b5b4b4;
    border-right: 1px solid gray;
    padding-left: 10px;
}
td{
    padding: 5px;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding-left: 10px;

}
